import styled from 'styled-components';

interface props {
  width: string;
  height: string;
}

const StyledImage = styled.img<props>`
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
`;

export default StyledImage;
