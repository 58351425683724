import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getBodyStyle } from 'styles/theme';

export const GridWrapper = styled.div`
  margin-top: 80px;
  padding: 0 16px;

  @media(${breakpoint.mdMin}) {
    padding: 0 24px;
  }

  @media(${breakpoint.lgMin}) {
    padding: 0 0 0 24px;
    width: 140%;
  }

  @media(${breakpoint.xxlMin}) {
    width: 880px;
    margin-left: 95px;
   }
`;

export const ListWrapper = styled.div`
  margin-top: 80px;
  padding: 0 16px;
 
  @media(${breakpoint.mdMin}) {
    padding: 0 24px;
  }

  @media(${breakpoint.lgMin}) {
    width: 660px;
    padding: 0;
  }

  @media(${breakpoint.xlMin}) {
   width: 850px;
   margin-left: 3%;
  }

  @media(${breakpoint.xxlMin}) {
    width: 880px;
    margin-left: 82px;
   }
`;

export const ListTitleWrapper = styled.div`
  font-family: ${font.graphikCond};
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 24px;
  @media(${breakpoint.xxlMin}) {
    margin-left: 70px;
  }
`;

export const ListContentWrapper = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'medium' })};
  overflow-wrap: break-word;

  @media(${breakpoint.xxlMin}) {
    margin-left: 70px;
  }
`;

export const GridTitleWrapper = styled.div`
  font-family: ${font.graphikCond};
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 24px;
  @media(${breakpoint.xxlMin}) {
    margin-left: 70px;
  }
`;

export const GridContentWrapper = styled.div`
  font-family: ${font.graphikCompact};
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  overflow-wrap: break-word;
  @media(${breakpoint.xxlMin}) {
    margin-left: 70px;
  }
`;
