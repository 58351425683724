import Link from 'components/Globals/Base/Link';
import PresentedByLogo from 'components/PresentedByLogo';
import { Container, Text } from './PresentedByStyles';

export interface PresentedByProps {
  logo: JSX.Element | string | null;
  linkTo: string | null;
  sponsorType: string;
  name: string;
  className?: string;
  topicSlug?: string;
}

const PresentedBy = ({ logo, linkTo, sponsorType, name, className = '', topicSlug = '' }: PresentedByProps) => {
  const displayLogo =
    typeof logo === 'string' ? (
      <PresentedByLogo
        src={logo}
        width='110'
        height='auto'
      />
    ) : (
      logo
    );

  let text = 'Brought to you by';
  if (sponsorType.toLowerCase() === 'sponsor') {
    text = 'Presented By';
  }
  if (topicSlug && topicSlug === 'leadership-next') {
    text = 'Powered By';
  }
  const eventCategory = sponsorType.toLowerCase() === 'sponsor' ? 'Sponsor' : 'Branded';

  if (!logo) {
    return null;
  }

  return (
    <Container className={className}>
      {linkTo ? (
        <Link
          className='banner-link'
          href={linkTo}
          trackingData={{
            contentPlacementCD: 'post header',
            eventAction: `${eventCategory} Logo Click`,
            eventCategory,
            eventLabel: `${name} Logo`,
          }}
        >
          <Text>{text}</Text>
          {displayLogo}
        </Link>
      ) : (
        <span className='banner-link'>
          <Text>{text}</Text>
          {displayLogo}
        </span>
      )}
    </Container>
  );
};

export default PresentedBy;
