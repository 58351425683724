import { PostContentItem } from 'components/ContentItem';
import { Grid, GridTopic } from 'interfaces/content/Ranking';
import { GridContentModuleWrapper, GridTitle, GridTopics } from './GridContentModuleStyles';

interface GridContentModuleProps {
  grid: Grid;
  dataCy?: string;
}

const GridContentModule = ({ grid, dataCy }: GridContentModuleProps) => {
  const { title, topics } = grid;
  const trackingData = {
    contentPlacementCD: 'related content',
    eventCategory: 'article',
  };

  return (
    <GridContentModuleWrapper>
      <GridTitle
        as='h2'
        $fontFamily='graphikCond'
        $size={{ default: 'level3', lgMin: 'level2' }}
      >
        {title}
      </GridTitle>
      <GridTopics data-cy={dataCy}>
        {topics?.map((topic: GridTopic) => {
          const postContentItemProps = {
            authors: topic.authors,
            date: topic.date,
            eyebrowSectionLabel: topic.sectionName,
            eyebrowSectionLink: topic.sectionUri,
            image: topic.image,
            title: topic.title,
            titleLink: topic.permalink,
          };

          return (
            <PostContentItem
              key={topic.databaseId}
              {...postContentItemProps}
              trackingData={{ ...trackingData, eventAction: `${topic.sectionName}`, eventLabel: `${topic.title}` }}
            />
          );
        })}
      </GridTopics>
    </GridContentModuleWrapper>
  );
};

export default GridContentModule;
