import { gql } from '@apollo/client';
import { ApolloQueryCache } from 'api/apolloClient';
import { NotFoundError } from 'api/errorHandling';
import { HeaderNav } from 'interfaces/navigation/Header';
import { Microsites } from 'constants/constants';
import buildMenuItemTree, { MenuItemResponse, MenuResponse } from './utils';

const GET_MENU = gql`
  query getMenu(
    $location1: MenuLocationEnum, 
    $location2: MenuLocationEnum, 
    $location3: MenuLocationEnum, 
    $location4: MenuLocationEnum, 
    $topicNavLocation: MenuLocationEnum
  ) {
    leftMenu: menus(where: { location: $location1 }) {
      nodes {
        menuItems(first: 100) {
          nodes {
            id
            label
            parentId
            url
          }
        }
      }
    }
    rightMenu: menus(where: { location: $location2 }) {
      nodes {
        menuItems(first: 100) {
          nodes {
            id
            label
            parentId
            url
          }
        }
      }
    }
    rightMenu2: menus(where: { location: $location3 }) {
      nodes {
        menuItems(first: 100) {
          nodes {
            id
            label
            parentId
            url
          }
        }
      }
    }
    rightRailMenu: menus(where: { location: $location4 }) {
      nodes {
        menuItems(first: 100) {
          nodes {
            id
            label
            parentId
            url
          }
        }
      }
    }
    topicNavigation: menus(where: { location: $topicNavLocation }) {
      nodes {
        menuItems(first: 100) {
          nodes {
            id
            label
            parentId
            url
          }
          pageInfo {
            hasNextPage
            totalCount
            endCursor
          }
        }
      }
    }
  }
`;

const GET_MENU_TOPIC_REMAINING = gql`
  query getMenu(
    $endCursor: String,
    $topicNavLocation: MenuLocationEnum
  ) {
    topicNavigation: menus(where: { location: $topicNavLocation }) {
      nodes {
        menuItems(first: 100, after: $endCursor) {
          nodes {
            id
            label
            parentId
            url
          }
          pageInfo {
            hasNextPage
            totalCount
            endCursor
          }
        }
      }
    }
  }
`;

export const cvsLink = 'https://www.cvshealth.com/?utm_source=fortune&utm_medium=ad&utm_campaign=fortune_well';

const mapMenu = (
  menuResponse: MenuResponse,
  subDomain: string,
  domainLogo: string | null,
  remainingTopicMenus: MenuItemResponse[],
): HeaderNav => {
  const leftMenu = menuResponse.leftMenu.nodes[0].menuItems.nodes;
  const rightMenu = menuResponse.rightMenu.nodes[0].menuItems.nodes;
  const rightMenu2 = menuResponse.rightMenu2?.nodes[0].menuItems.nodes;
  const rightRailMenu = menuResponse.rightRailMenu?.nodes[0].menuItems.nodes;
  const topicNavigation = [...menuResponse.topicNavigation.nodes[0].menuItems.nodes, ...remainingTopicMenus];
  const finalRightMenu =
    Microsites.Recommends === subDomain || Microsites.Education === subDomain ?
      [...rightMenu, ...rightMenu2] :
      rightMenu;

  return {
    domainLogo,
    leftMenu: buildMenuItemTree(leftMenu),
    rightMenu:
      Microsites.Recommends === subDomain || Microsites.Education === subDomain ?
        [] :
        buildMenuItemTree(finalRightMenu),
    rightRailMenu: buildMenuItemTree(rightRailMenu),
    subDomain,
    topicNavigation: buildMenuItemTree(topicNavigation),
  };
};

const getRemainingMenuCache = new ApolloQueryCache<MenuResponse>('[getMenu_remaining]', GET_MENU_TOPIC_REMAINING, 300);
const getRemainingTopicNavItemsRecursive = async (
  acc: any[],
  topicNavLocation: string,
  endCursor: string,
): Promise<MenuItemResponse[]> => {
  const menuResponse: MenuResponse = await getRemainingMenuCache.fetch({
    endCursor,
    topicNavLocation,
  });
  const errorLabel = '[getMenu_remaining]';

  acc.push(...menuResponse.topicNavigation.nodes[0].menuItems.nodes);
  if (menuResponse.topicNavigation.nodes[0].menuItems.pageInfo?.hasNextPage) {
    await getRemainingTopicNavItemsRecursive(
      acc,
      topicNavLocation,
      menuResponse.topicNavigation.nodes[0].menuItems.pageInfo?.endCursor,
    );
  }

  try {
    return acc as MenuItemResponse[];
  } catch (e) {
    throw new Error(`${errorLabel} ${(e as Error).stack}`);
  }
};

const getMenuCache = new ApolloQueryCache<MenuResponse>('[getMenu]', GET_MENU, 300);

const getMenu = async (
  location1: string,
  location2: string,
  topicNavLocation: string,
  subDomain: string,
  domainLogo: string | null,
  location3?: string,
  location4?: string,
) => {
  const menuResponse: MenuResponse = await getMenuCache.fetch({
    location1,
    location2,
    location3: location3 || null,
    location4: location4 || null,
    topicNavLocation,
  });
  const errorLabel = '[getMenu]';
  let remainingTopicMenus: MenuItemResponse[] = [];

  // TODO: FECM-512 - cleanup implementation to support recursive requests for remaining menu items in all menus
  if (
    (Microsites.Recommends === subDomain || Microsites.Education === subDomain) &&
    menuResponse.topicNavigation.nodes[0].menuItems.pageInfo?.hasNextPage
  ) {
    remainingTopicMenus = await getRemainingTopicNavItemsRecursive(
      [],
      topicNavLocation,
      menuResponse.topicNavigation.nodes[0].menuItems.pageInfo?.endCursor,
    );
  }

  // TODO: we should catch this in the component and decide what to do there in care we
  // don't get the whole menu, just parts of it
  if (!menuResponse || !menuResponse.rightMenu || !menuResponse.leftMenu || !menuResponse.topicNavigation) {
    throw new NotFoundError(`${errorLabel} Menu not found`);
  }

  try {
    return mapMenu(menuResponse, subDomain, domainLogo, remainingTopicMenus);
  } catch (e) {
    throw new Error(`${errorLabel} ${(e as Error).stack}`);
  }
};

export default getMenu;
