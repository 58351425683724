/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { RightRailSimple } from 'components/Ad/AdSlot';
import { ContentTitle } from 'components/ContentTitle';
import FAQ from 'components/FAQ';
import Link, { changeRoute } from 'components/Globals/Base/Link';
import PresentedBy from 'components/PresentedBy';
import YearFilter from 'components/Globals/SlideFilter';
import { gridResponsiveSettings, listResponsiveSettings } from 'components/Globals/SlideFilter/responsiveSettings';
import RankingItem from 'components/Ranking/RankingItem/RankingItem';
import VodVideo from 'components/VodVideo';
import { FranchiseListItemPreview, FranchisePage } from 'interfaces/content/Ranking';
import { CvsLogo } from 'icons/CvsLogo';
import { cvsLink } from 'api/Header/getMenu';
import RawHtml from 'utils/miscUtils/rawHtml';
import Methodology from 'components/Methodology';
import {
  ContentModules,
  GridContentModuleWrapper,
} from 'pages/RankingCompanyFranchise/RankingCompanyFranchisePageStyles';
import GridContentModule from 'components/GridContentModule';
import useBreakpoint from 'hooks/useBreakpoint';
import SimpleInStream from 'components/Ad/AdSlot/SimpleInStream';
import getWellRankingListTestId from 'constants/testsIds/wellRankingList';
import StnVideo from 'components/StnVideo';
import {
  CallsToAction,
  DescriptionText,
  DescriptionVideo,
  DescriptionWrapper,
  FaqWrapper,
  Header,
  MethodologyWrapper,
  RankingContentWrapper,
  RankingList,
  RankingListTitle,
  RankingListWrapper,
  RankingSidebar,
  RankingWrapper,
  WrapperTitle,
} from './SimpleRankingPageStyles';

export interface SimpleRankingPageProps {
  franchiseList: FranchisePage;
  subDomain?: string;
  hidePresentedBy?: boolean;
}

const SimpleRankingPage: React.FC<SimpleRankingPageProps> = ({
  franchiseList,
  subDomain,
  hidePresentedBy,
}: SimpleRankingPageProps) => {
  const { faq, listType, methodology, title, link, year, years, gridItemsPerRow } = franchiseList;
  const isParentPage = link.search(/\d{4}$/) === -1;
  const isDevelop = process.env.ENVIRONMENT === 'dev';
  const renderFaq = faq.length !== 0 && isParentPage;
  const renderMethodology = isDevelop ? methodology && isParentPage : false;

  const isDesktop = useBreakpoint('xlMin');
  const isMobile = useBreakpoint('mdMax');
  const isTablet = !isMobile && !isDesktop;

  const gridLists = franchiseList.lists;
  const columnNumber = gridItemsPerRow || 4;
  const convertYears = years.map((yr) => Number(yr));
  const rankingListItemMapper = (item: FranchiseListItemPreview) => (
    <RankingItem
      key={item.title}
      franchiseItem={item}
      trackingData={{
        eventAction: `${franchiseList.title}`,
        eventCategory: 'rankings',
        eventLabel: `${item.title} : ${franchiseList.year}`,
        platformCD: subDomain,
      }}
      showOrder={franchiseList.showOrder}
    />
  );

  return (
    <div className='franchise-content'>
      {subDomain && !hidePresentedBy && (
        <PresentedBy
          linkTo={cvsLink}
          logo={<CvsLogo />}
          name='Cvs'
          sponsorType='Sponsor'
        />
      )}
      {subDomain ? (
        <Header data-cy={getWellRankingListTestId('TITLE')}>{franchiseList.title}</Header>
      ) : (
        <WrapperTitle>
          <ContentTitle
            tag='h1'
            size={60}
            font='graphikCond'
          >
            {franchiseList.title}
          </ContentTitle>
        </WrapperTitle>
      )}
      <RankingWrapper>
        <DescriptionWrapper>
          <DescriptionText data-cy={getWellRankingListTestId('DESCRIPTION')}>
            {RawHtml({
              html: franchiseList.description,
            })}
            <CallsToAction>
              {franchiseList.callsToAction.map((callToAction, index) =>
                (index === 0 ? (
                  <Link
                    className='black-button'
                    dataCy={getWellRankingListTestId('CALL_TO_ACTION')}
                    href={callToAction.link}
                    key={callToAction.label}
                    type='primaryButton'
                    size={{ default: 'large' }}
                  >
                    {callToAction.label}
                  </Link>
                ) : (
                  <Link
                    className='link'
                    dataCy={getWellRankingListTestId('READ_MORE')}
                    href={callToAction.link}
                    key={callToAction.label}
                    type='bluePrimary'
                  >
                    {callToAction.label}
                  </Link>
                )))}
            </CallsToAction>
          </DescriptionText>
          {(franchiseList.vodVideoId || franchiseList.stnVideoId) && (
            <DescriptionVideo>
              {franchiseList.stnVideoId ? (
                <StnVideo videoId={franchiseList.stnVideoId} />
              ) : (
                franchiseList.vodVideoId && (
                  <VodVideo
                    dataCy={getWellRankingListTestId('VIDEO')}
                    videoId={franchiseList.vodVideoId}
                  />
                )
              )}
            </DescriptionVideo>
          )}
        </DescriptionWrapper>
        <RankingContentWrapper>
          <RankingListWrapper>
            <YearFilter
              listType={listType}
              itemClassName='year'
              className='year-filter'
              valueList={franchiseList.years}
              responsiveSettings={listType === 'list' ? listResponsiveSettings : gridResponsiveSettings}
              selectedValue={franchiseList.year}
              changeSelectedValue={(newYear: string) => {
                const currentYr = convertYears[0];
                const currentYearCheck =
                  Number(newYear) === currentYr ?
                    `/ranking/${franchiseList.slug}/` :
                    `/ranking/${franchiseList.slug}/${newYear}/`;

                return changeRoute(
                  subDomain ? `/${subDomain}/ranking/${franchiseList.slug}/${newYear}/` : currentYearCheck,
                );
              }}
            />
            {listType === 'list' ? (
              <RankingList
                data-cy={getWellRankingListTestId('PLACES_LIST')}
                $num={columnNumber}
              >
                {gridLists[0].items.map(rankingListItemMapper)}
              </RankingList>
            ) : (
              gridLists.map((gridList) => (
                <section key={gridList.title}>
                  <RankingListTitle>
                    <ContentTitle
                      tag='h2'
                      size={34}
                      font='graphikCond'
                    >
                      {gridList.title}
                    </ContentTitle>
                  </RankingListTitle>
                  <RankingList $num={columnNumber}>{gridList.items.map(rankingListItemMapper)}</RankingList>
                </section>
              ))
            )}
            {renderMethodology && (
              <MethodologyWrapper>
                <Methodology
                  listType={listType}
                  methodology={methodology}
                  title={title}
                  year={year}
                />
              </MethodologyWrapper>
            )}
            {renderFaq && (
              <FaqWrapper>
                <FAQ faq={faq} />
              </FaqWrapper>
            )}
          </RankingListWrapper>
          <RankingSidebar>
            {isTablet || isMobile ? (
              <SimpleInStream id={0} />
            ) : (
              <RightRailSimple
                id={0}
                dataCy={getWellRankingListTestId('AD')}
                page='people'
              />
            )}
          </RankingSidebar>
        </RankingContentWrapper>
        {franchiseList.contentModules.grid?.topics && franchiseList.contentModules.grid?.topics?.length !== 0 && (
          <ContentModules>
            <GridContentModuleWrapper>
              <GridContentModule grid={franchiseList.contentModules.grid} />
            </GridContentModuleWrapper>
          </ContentModules>
        )}
      </RankingWrapper>
    </div>
  );
};

export default SimpleRankingPage;
