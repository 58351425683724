import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import Heading from 'styles/typography/Heading';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
 
  @media(${breakpoint.mdMin}) {
    margin-top: 80px;
  }

  @media(${breakpoint.lgMin}) {
   margin-left:16px;
    width: 965px;
  }

  @media(${breakpoint.xxlMin}) {
    width: 880px;
    margin-left: 82px;
   }
`;

export const ContentWrapper = styled.div<{ $methodology?: string }>`
  margin: 0 16px;

  @media(${breakpoint.mdMin}) {
    margin: 0 24px;
  }

  @media(${breakpoint.lgMin}) {
    ${({ $methodology }) => ($methodology ? ' width: 100%;' : ' width: 70%;')}
    margin: 0;
  }
  
  @media(${breakpoint.xlMin}) {
    width: 832px;
    margin-left: 5%;
    ${({ $methodology }) => ($methodology ? 'margin-left:0' : null)}
  }

  @media(${breakpoint.xxlMin}) {
    margin-left: 8%;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 24px;
`;

export const QuestionsWrapper = styled.div`
  font-family: ${font.graphikCond};
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 16px;
`;

export const AnswersWrapper = styled.div`
  font-family: ${font.graphikCond};
  font-weight: 400;
  font-size: 18px;
  line-height: 144%;
  margin-bottom: 24px;
`;

export const Title = styled(Heading)`
  margin-bottom: 24px;
`;

export const AdWrapper = styled.div`
  display: none;

  @media(${breakpoint.lgMin}) {
    display: block;  
  }
`;

export const Line = styled.hr`
  @media(${breakpoint.lgMin}) {
    width: 100%;
  }

  @media(${breakpoint.xlMin}) {
    margin-right:0;
  }

  @media(${breakpoint.xxlMin}) {
    width: 880px;
   }

`;
