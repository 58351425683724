import styled from 'styled-components';
import { font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const RankingItemWrapper = styled.li<{ theme: Theme }>`
  position: relative;

  a {
    text-decoration: none;

    &:hover > * {
      color: ${getColor('textWhite')}
    }
  }

  img {
    transition: 0.35s ease-in-out;
    &:hover {
      filter: brightness(80%);
      transition: 0.35s ease-in-out;
    }
  }
`;

export const RakingItemInnerWrapper = styled.div<{ theme: Theme }>`
  align-items: center;
  background-color: ${getColor('surfaceWhite')};
  line-height: 24px;
  padding-top: 8px;
`;

export const FranchiseItemTitle = styled.span<{ theme: Theme }>`
  color: ${getColor('textPrimary')};
  font-family: ${font.graphikCond};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
  &:hover {
    color: ${getColor('textLink')};
  }
`;

export const Rank = styled.span<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -8px;
  margin-left: -8px;
  position: relative;
  z-index: 10;
  background-color: ${getColor('backgroundTertiary')};
  color: ${getColor('textWhite')};
  font-size: 16px;
  font-weight: 700;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  font-family: ${font.graphikCond};
  &:hover {
    filter: brightness(80%);
  }
`;
