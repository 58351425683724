import { NextCustomImage } from 'components/Image';
import Link from 'components/Globals/Base/Link';
import { FranchiseListItemPreview } from 'interfaces/content/Ranking';
import { EventData } from 'services/Gtm/entities';
import { FranchiseItemTitle, RakingItemInnerWrapper, RankingItemWrapper, Rank } from './RankingItemStyles';

export interface RankingItemProps {
  franchiseItem: FranchiseListItemPreview;
  trackingData: EventData;
  showOrder: boolean;
}

const RankingItem: React.FC<RankingItemProps> = ({ franchiseItem, trackingData, showOrder }: RankingItemProps) => {
  const { title, image, slug } = franchiseItem;
  return (
    <RankingItemWrapper>
      <Link
        href={slug}
        trackingData={trackingData}
      >
        {showOrder && <Rank className='no-hover'> {franchiseItem.rank} </Rank>}
        {image && (
          <NextCustomImage
            src={image}
            alt={title}
            width={184}
            height={123}
          />
        )}
        <RakingItemInnerWrapper>
          <FranchiseItemTitle>{title}</FranchiseItemTitle>
        </RakingItemInnerWrapper>
      </Link>
    </RankingItemWrapper>
  );
};

export default RankingItem;
