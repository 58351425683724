import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const GridContentModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
 
  @media(${breakpoint.lgMin}) {
    padding: 24px;
  }
  @media(${breakpoint.xlMin}) {
    padding: 48px 0 0 48px;
  }
`;

export const GridTitle = styled(Heading)`
  border-bottom: 1px solid ${getColor('borderTertiary')};
  margin: 16px 16px 24px;

  @media(${breakpoint.mdMin}) {
    margin: 32px 0;
  }
`;

export const GridTopics = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px;
  li {
    flex-shrink: 0;
    margin: 0 40px 24px 0;
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media(${breakpoint.mdMin}) {
    display: grid;
    grid-template-columns: 47% 47%;
    justify-content: space-evenly;
    padding: 0;

    li {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media(${breakpoint.lgMin}) {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    justify-content: space-evenly;

  }
`;
