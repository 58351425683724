import RawHtml from 'utils/miscUtils/rawHtml';
import {
  GridContentWrapper,
  GridTitleWrapper,
  GridWrapper,
  ListContentWrapper,
  ListTitleWrapper,
  ListWrapper,
} from './MethodologyStyles';

export interface MethodologyProps {
  listType: string;
  methodology: string | undefined;
  title: string;
  year: string;
}

const Methodology = (props: MethodologyProps) => {
  const { listType, methodology, title, year } = props;
  return listType === 'grid' ? (
    <GridWrapper>
      <GridTitleWrapper>
        Methodology for {title} {year}
      </GridTitleWrapper>
      <GridContentWrapper>{RawHtml({ html: methodology })}</GridContentWrapper>
    </GridWrapper>
  ) : (
    <ListWrapper>
      <ListTitleWrapper>
        Methodology for {title} {year}
      </ListTitleWrapper>
      <ListContentWrapper>{RawHtml({ html: methodology })}</ListContentWrapper>
    </ListWrapper>
  );
};

export default Methodology;
