const WELL_RANKING_LIST_TEST_IDS = {
  AD: 'well-ranking-list-add',
  CALL_TO_ACTION: 'well-ranking-list-call-to-action',
  DESCRIPTION: 'well-ranking-list-description',
  PLACES_LIST: 'well-ranking-list-places',
  READ_MORE: 'well-ranking-list-read-more',
  TITLE: 'well-ranking-list-title',
  VIDEO: 'well-ranking-list-video',
};

export type TestIds = {
  [key in keyof typeof WELL_RANKING_LIST_TEST_IDS]?: string;
};

const getWellRankingListTestId = (value: keyof TestIds) => WELL_RANKING_LIST_TEST_IDS[value];

export default getWellRankingListTestId;
