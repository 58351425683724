import { ListWrapper, QuestionsWrapper, AnswersWrapper, Wrapper, ContentWrapper, Line, Title } from './FAQStyles';

interface FAQProps {
  faq?: { answer: string; question: string }[];
  methodology?: string | undefined;
}

const FAQ = (props: FAQProps) => {
  const { faq, methodology } = props;
  return (
    <Wrapper>
      <ContentWrapper $methodology={methodology}>
        <Title
          as='h2'
          $fontFamily='graphikCond'
          $size={{ default: 'level1' }}
        >
          Frequently Asked Questions
        </Title>
        <Line />
        <ListWrapper>
          {faq ?
            faq.map((faqObj) => (
              <>
                <QuestionsWrapper key={1}>{faqObj.question}</QuestionsWrapper>
                <AnswersWrapper>{faqObj.answer}</AnswersWrapper>
              </>
            )) :
            null}
        </ListWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default FAQ;
