import styled from 'styled-components';
import { color, font } from 'styles/globals';

export const Container = styled.div`
  .banner-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    height: 20px;
    width: 100px;
  }
`;

export const Text = styled.span`
  font-family: ${font.graphikCond};
  font-size: 14px;
  line-height: 16.8px;
  color: ${color.darkGray};
  margin-right: 8px;
  text-transform: uppercase;
`;
