import StyledImage from './PresentedByLogoStyles';

export interface PresentedByLogoProps {
  src: string;
  width: string;
  height: string;
}

const PresentedByLogo = (props: PresentedByLogoProps) => {
  const { src, width, height } = props;

  return (
    <StyledImage
      src={src}
      width={width}
      height={height}
    />
  );
};

export default PresentedByLogo;
