/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export const CvsLogo = () => (
  <svg version='1.0' xmlns='http://www.w3.org/2000/svg'
    width='9836.000000pt' height='1200.000000pt' viewBox='0 0 9836.000000 1200.000000'
    preserveAspectRatio='xMidYMid meet'>

    <g transform='translate(0.000000,1200.000000) scale(0.100000,-0.100000)'
      fill='#111111' stroke='none'>
      <path fill='#CC0000' d='M21160 11994 c-311 -21 -393 -27 -505 -40 -1838 -209 -3252 -1083
-4095 -2534 -385 -664 -647 -1468 -759 -2340 -93 -714 -91 -1498 5 -2207 227
-1686 992 -3028 2203 -3867 747 -517 1639 -837 2661 -955 332 -38 486 -46 910
-46 521 0 853 25 1280 96 1603 265 2787 1034 3485 2264 296 521 509 1170 604
1845 24 170 51 422 51 480 l0 40 -1720 0 c-1369 0 -1720 -3 -1720 -12 -1 -41
-43 -301 -64 -393 -68 -303 -193 -586 -351 -797 -88 -117 -272 -292 -391 -371
-230 -153 -493 -246 -814 -289 -122 -16 -476 -15 -602 1 -739 95 -1248 465
-1573 1144 -266 556 -392 1357 -356 2266 32 803 151 1366 387 1831 122 242
226 384 409 558 267 255 606 407 1035 464 158 21 528 15 698 -11 445 -67 751
-210 1013 -472 271 -270 434 -640 503 -1137 9 -62 16 -115 16 -117 0 -3 781
-5 1736 -5 l1737 0 -7 93 c-4 50 -18 178 -31 282 -163 1292 -696 2339 -1555
3054 -817 680 -1855 1053 -3235 1162 -130 10 -851 20 -955 13z'/>
      <path fill='#CC0000' d='M41350 11990 c-1631 -91 -2868 -611 -3585 -1505 -196 -245 -398 -603
-500 -888 -89 -249 -157 -558 -186 -847 -17 -161 -17 -599 0 -760 51 -504 188
-939 414 -1315 223 -372 542 -703 922 -957 488 -326 1611 -710 3330 -1140 895
-223 1111 -291 1335 -419 112 -64 240 -195 284 -290 47 -102 66 -197 66 -326
0 -129 -16 -198 -70 -309 -135 -276 -484 -454 -993 -505 -157 -15 -635 -6
-772 15 -586 90 -909 322 -1094 786 -27 68 -80 243 -95 313 l-6 27 -1776 0
-1776 0 7 -62 c85 -774 327 -1451 716 -1999 236 -334 550 -646 890 -886 673
-475 1538 -772 2559 -878 554 -57 1214 -59 1805 -4 2176 200 3562 1134 4005
2700 70 248 118 521 140 803 13 165 13 569 0 711 -51 564 -209 1017 -496 1425
-354 504 -945 959 -1584 1223 -420 173 -822 291 -2027 596 -1384 351 -1593
412 -1848 539 -230 114 -355 254 -401 448 -25 107 -15 304 20 409 31 90 59
143 110 204 124 148 313 240 601 292 165 31 618 34 795 6 289 -45 508 -128
668 -251 89 -68 202 -194 255 -284 56 -95 118 -253 142 -364 l17 -78 1719 0
1719 0 0 38 c0 21 -7 92 -16 157 -215 1648 -1237 2738 -2977 3175 -379 95
-752 153 -1227 190 -181 14 -897 21 -1090 10z'/>
      <path fill='#CC0000' d='M3785 11961 c-263 -49 -455 -127 -675 -274 -97 -65 -208 -173 -1388
-1351 -706 -705 -1310 -1312 -1341 -1351 -514 -623 -505 -1537 21 -2160 42
-49 1593 -1606 3447 -3460 l3371 -3370 3341 3340 c1838 1837 3381 3385 3431
3440 320 356 477 810 438 1267 -30 351 -138 631 -358 923 -49 66 -2419 2445
-2571 2581 -230 207 -512 349 -816 411 -139 28 -513 25 -649 -5 -294 -66 -537
-185 -761 -374 -49 -42 -533 -520 -1075 -1062 l-985 -986 -1040 1038 c-637
636 -1068 1059 -1112 1091 -193 140 -388 228 -638 287 -97 23 -136 26 -325 30
-178 3 -232 0 -315 -15z'/>
      <path fill='#CC0000' d='M26232 11671 c2 -5 858 -2560 1903 -5680 l1900 -5671 1815 0 1815 0
1829 5438 c1006 2990 1866 5546 1912 5680 l83 242 -1792 0 -1792 0 -1020
-3660 c-561 -2013 -1023 -3660 -1026 -3660 -3 0 -431 1637 -952 3638 -521
2000 -950 3647 -953 3660 l-6 22 -1860 0 c-1054 0 -1858 -4 -1856 -9z'/>
      <path d='M48040 11150 l0 -530 570 0 570 0 0 -4625 0 -4625 -567 -2 -568 -3 0
-530 0 -530 1798 -3 1797 -2 0 535 0 535 -565 0 -565 0 0 2265 0 2265 2850 0
2850 0 0 -2265 0 -2265 -570 0 -570 0 0 -535 0 -535 1800 0 1800 0 0 535 0
535 -570 0 -570 0 0 4625 0 4625 570 0 570 0 0 530 0 530 -1800 0 -1800 0 0
-530 0 -530 570 0 570 0 0 -1840 0 -1840 -2850 0 -2850 0 0 1840 0 1840 565 0
565 0 0 530 0 530 -1800 0 -1800 0 0 -530z'/>
      <path d='M77700 11165 l0 -515 530 0 530 0 0 -4157 c0 -2625 4 -4231 10 -4356
35 -684 199 -1147 520 -1467 316 -317 764 -470 1375 -472 243 0 499 32 691 88
l54 15 0 515 c0 483 -1 514 -17 514 -10 0 -70 -10 -133 -21 -79 -13 -182 -21
-330 -25 -190 -5 -226 -4 -311 14 -326 68 -486 279 -556 736 -16 103 -18 441
-20 4879 l-3 4767 -1170 0 -1170 0 0 -515z'/>
      <path d='M86150 11165 l0 -515 530 0 530 0 0 -4655 0 -4655 -527 -2 -528 -3
-3 -518 -2 -517 1702 2 1703 3 0 515 0 515 -533 3 -533 2 4 1908 c4 2039 2
1972 53 2272 25 151 88 385 138 513 283 729 831 1136 1691 1253 185 25 631 26
800 1 278 -42 464 -97 680 -204 432 -214 747 -580 930 -1077 78 -212 140 -513
164 -791 7 -77 11 -789 11 -1997 l0 -1878 -527 -2 -528 -3 -3 -518 -2 -517
1702 2 1703 3 0 515 0 515 -532 3 -533 2 0 1869 c0 1165 -4 1917 -10 1997 -31
399 -117 797 -245 1144 -106 286 -304 643 -495 890 -89 115 -363 391 -480 484
-444 351 -966 560 -1605 643 -175 22 -698 25 -870 5 -742 -89 -1312 -344
-1783 -797 -53 -51 -133 -136 -177 -188 l-80 -95 -3 2194 -2 2194 -1170 0
-1170 0 0 -515z'/>
      <path d='M82577 10163 c-4 -3 -7 -456 -7 -1005 l0 -998 -605 0 -605 0 0 -515
0 -515 605 0 605 0 0 -2482 c0 -1568 4 -2538 10 -2633 76 -1124 601 -1685
1685 -1800 363 -39 843 -8 1123 71 l52 15 0 518 0 517 -107 -17 c-197 -31
-364 -42 -591 -36 -233 6 -314 18 -432 64 -278 111 -412 338 -450 763 -6 74
-10 993 -10 2568 l0 2452 795 0 795 0 0 515 0 515 -795 0 -795 0 0 1005 0
1005 -633 0 c-349 0 -637 -3 -640 -7z'/>
      <path d='M63000 8383 c-467 -28 -933 -131 -1360 -300 -1222 -483 -2076 -1529
-2350 -2875 -66 -326 -93 -611 -93 -978 0 -631 99 -1176 310 -1710 192 -485
458 -899 808 -1259 283 -290 558 -499 915 -692 882 -480 2068 -622 3142 -378
961 218 1750 737 2307 1517 126 176 328 535 380 675 l11 27 -638 0 -637 0 -91
-138 c-235 -352 -523 -612 -899 -808 -310 -162 -637 -256 -1060 -306 -167 -20
-654 -16 -835 5 -572 69 -1044 254 -1425 557 -525 419 -866 1086 -959 1880 -9
74 -19 152 -21 173 l-5 37 3375 0 3375 0 0 418 c0 552 -23 814 -105 1198 -352
1639 -1541 2719 -3225 2928 -258 32 -661 45 -920 29z m755 -1088 c550 -81
1015 -300 1384 -651 297 -282 528 -653 678 -1089 66 -191 130 -475 149 -659
l7 -66 -2735 0 -2735 0 14 103 c103 777 467 1440 1018 1852 364 272 763 432
1265 505 266 39 709 42 955 5z'/>
      <path d='M71737 8379 c-1242 -102 -2305 -766 -2952 -1843 -530 -883 -727
-2028 -539 -3131 263 -1540 1292 -2752 2689 -3168 774 -231 1599 -217 2335 39
409 142 762 344 1110 635 134 112 367 348 460 467 l65 82 3 -580 2 -580 1170
0 1170 0 0 520 0 520 -530 0 -530 0 0 2895 0 2895 530 0 530 0 0 515 0 515
-1170 0 -1170 0 -2 -576 -3 -575 -60 73 c-623 761 -1486 1212 -2480 1298 -137
11 -483 11 -628 -1z m907 -1099 c1190 -179 2055 -1109 2265 -2435 33 -209 44
-364 44 -620 -1 -541 -95 -1014 -292 -1458 -151 -341 -341 -620 -595 -872
-387 -384 -855 -618 -1423 -711 -131 -21 -546 -30 -695 -15 -442 46 -821 177
-1178 408 -611 396 -1046 1073 -1209 1879 -202 1003 0 2068 531 2786 453 614
1114 987 1873 1057 161 15 518 5 679 -19z'/>
      <path d='M97021 2574 c-46 -8 -124 -28 -174 -44 -348 -117 -605 -375 -716
-718 -47 -145 -56 -212 -55 -407 1 -168 3 -188 32 -300 116 -441 437 -745 879
-832 108 -21 327 -23 433 -4 156 28 326 97 455 184 158 108 320 307 391 482
62 151 85 262 91 431 8 221 -19 375 -96 554 -202 468 -719 741 -1240 654z
m371 -208 c405 -78 689 -428 714 -880 12 -198 -23 -373 -106 -541 -90 -181
-219 -310 -395 -395 -126 -61 -201 -80 -345 -87 -307 -15 -564 102 -742 337
-144 189 -217 474 -188 735 41 388 252 673 590 799 122 45 329 59 472 32z'/>
      <path d='M96775 2038 c-3 -7 -4 -299 -3 -648 l3 -635 108 -3 107 -3 0 276 0
276 101 -3 101 -3 165 -272 165 -273 119 0 c65 0 119 2 119 5 0 3 -79 130
-176 282 -96 151 -174 277 -172 278 2 2 32 11 68 20 82 21 139 54 184 106 53
62 71 125 71 245 0 96 -2 105 -35 170 -56 108 -142 161 -304 183 -114 16 -615
15 -621 -1z m658 -207 c70 -34 89 -69 85 -158 -4 -85 -22 -115 -89 -153 -42
-24 -53 -25 -241 -28 l-198 -4 0 187 0 187 198 -4 c184 -3 200 -5 245 -27z'/>
    </g>
  </svg>
);
